import { changeLanguage } from "i18next";  
import {
  ITemplateAction,
  ITemplateState,
  templateActionTypeEnum,
  templateInitialState,
} from "store";

//Actiondan burası tetikllenir, bu verilerde tüm componentlere gider, sonrasaında kayıt edilir
export const TemplateReducer = (
  state: ITemplateState = templateInitialState,
  action: ITemplateAction
): ITemplateState => {
  //action.type init dışında new instance oluşması gerekiyor
  const result: ITemplateState = {
    sidebarShow: state.sidebarShow,
    notificationCount: state.notificationCount,
    language : state.language
  };
    
  switch (action.type) {
    // case TemplateActionTypeEnum.Init:
    //   //LocalStorage değerini yerine init değerini okumanması sağlandı
    //   return templateInitialState;
    case templateActionTypeEnum.SideBarShow:
      result.sidebarShow = action.payload;
      return result;
    case templateActionTypeEnum.ChangeNotificationCount:
      result.notificationCount = action.payload;
      return result;
      case templateActionTypeEnum.Language:
        result.language = action.payload; 

        // const lan : Dil = action.payload;
        // const i18nLan = lan.toString().substring(0,2).toLocaleLowerCase() ?? "";
        
        changeLanguage(action.payload.toString().toLowerCase() ?? "");
        return result;
    default:
      return state;
  }
};
